import { defineMessages } from 'react-intl';

export default defineMessages({
	company: {
		id: 'footer.company',
		defaultMessage: 'Company',
		description: 'Footer Company Link',
	},
	about: {
		id: 'footer.about',
		defaultMessage: 'About',
		description: 'Footer About Link',
	},
	careers: {
		id: 'footer.careers',
		defaultMessage: 'Careers',
		description: 'Footer Careers Link',
	},
	press: {
		id: 'footer.press',
		defaultMessage: 'Press',
		description: 'Footer Press Link',
	},
	blog: {
		id: 'footer.blog',
		defaultMessage: 'Blog',
		description: 'Footer Blog Link',
	},
	instructor: {
		id: 'footer.instructor',
		defaultMessage: 'Meet our Instructor',
		description: 'Footer Instructor Link',
	},
	science: {
		id: 'footer.science',
		defaultMessage: 'Calm Science',
		description: 'Footer Science Link',
	},
	partnerships: {
		id: 'footer.partnerships',
		defaultMessage: 'Brand Partnerships',
		description: 'Footer Partnerships Link',
	},
	offers: {
		id: 'footer.offers',
		defaultMessage: 'Offers',
		description: 'Footer Offers Link',
	},
	gift: {
		id: 'footer.gift',
		defaultMessage: 'Buy a Gift',
		description: 'Footer Buy Gift Link',
	},
	redeem: {
		id: 'footer.redeem',
		defaultMessage: 'Redeem a Gift',
		description: 'Footer Redeem Gift Link',
	},
	familyPlan: {
		id: 'footer.familyPlan',
		defaultMessage: 'Family Plan',
		description: 'Footer Family Plan Link',
	},
	business: {
		id: 'footer.business',
		defaultMessage: 'Calm for Organizations',
		description: 'Footer Business Link',
	},
	calmHealth: {
		id: 'footer.calmHealth',
		defaultMessage: 'Calm Health',
		description: 'DO NOT TRANSLATE',
	},
	calmStudents: {
		id: 'footer.calmStudents',
		defaultMessage: 'Calm for Students',
		description: 'Footer Students Link',
	},
	help: {
		id: 'footer.help',
		defaultMessage: 'Help',
		description: 'Footer Help Link',
	},
	faq: {
		id: 'footer.faq',
		defaultMessage: 'FAQ',
		description: 'Footer FAQ Link',
	},
	contact: {
		id: 'footer.contact',
		defaultMessage: 'Contact Us',
		description: 'Footer Contact Us Link',
	},
	terms: {
		id: 'footer.terms',
		defaultMessage: 'Terms',
		description: 'Footer Terms Link',
	},
	privacy: {
		id: 'footer.privacyPolicy',
		defaultMessage: 'Privacy Policy',
		description: 'Footer Privacy Link',
	},
	californiaPrivacyNotice: {
		id: 'footer.californiaPrivacyNotice',
		defaultMessage: 'CCPA Notice',
		description: 'Footer California Privacy Notice Link',
	},
	accessibilityStatement: {
		id: 'footer.accessibilityStatement',
		defaultMessage: 'Accessibility Statement',
		description: 'Footer Accessibility Statement Link',
	},
	optOut: {
		id: 'footer.optOut',
		defaultMessage: 'Opt Out of Targeted Ads',
		description: 'Footer Opt Out of Targeted Ads Link',
	},
	privacyChoices: {
		id: 'footer.privacyChoices',
		defaultMessage: 'Your Privacy Choices',
		description: 'Footer Privacy Choices Link',
	},
	cookies: {
		id: 'footer.cookies',
		defaultMessage: 'Cookies',
		description: 'Footer Cookies Link',
	},
	copyright: {
		id: 'footer.copyright',
		defaultMessage: 'Copyright © {year} Calm. All rights reserved',
		description: 'Footer copyright with dynamic year',
	},
	socialIconAriaLabel: {
		id: 'footer.socialIconAriaLabel',
		defaultMessage: "Link to Calm's {socialMedia}",
		description: 'A screen reader only label for social media links',
	},
});
