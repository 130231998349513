import querystring from 'query-string';

import { AdminTermsRes } from '@/components/terms/AdminTerms/types';
import { Device } from '@/store/device/types';
import apiRequest from '@/utils/apiRequest';
import {
	eeaCountries,
	getCountryNameFromDevice,
	getThreeCharCountryCodeFromCountryName,
} from '@/utils/countries';

export const handleGetAdminTerms = async (queryParams: Record<string, string>) => {
	try {
		const res = await apiRequest<AdminTermsRes>({
			endpoint: `terms?${querystring.stringify(queryParams)}`,
			method: 'GET',
			userData: {},
		});
		return res?.data?.terms ?? null;
	} catch (err) {
		return null;
	}
};

export const getAdminAmexTerms = async (
	device: Device | null,
	path: '/terms-amex' | '/terms-amex-global',
) => {
	const queryParams: Record<string, string> = {
		language: 'en',
		country: 'Generic',
		url: path,
	};
	if (path === '/terms-amex') {
		return handleGetAdminTerms(queryParams);
	}
	const countryName = getCountryNameFromDevice(device);
	const threeCharCountryCode = getThreeCharCountryCodeFromCountryName(countryName);
	const countryCodeToLanguage: Record<string, string> = {
		DEU: 'de',
		JPN: 'en',
		ESP: 'es',
		FRA: 'fr',
	};
	if (threeCharCountryCode in countryCodeToLanguage) {
		queryParams.language = countryCodeToLanguage[threeCharCountryCode];
		queryParams.country = threeCharCountryCode;
	}
	return handleGetAdminTerms(queryParams);
};

export const getAdminSamsungTerms = async () => {
	const queryParams: Record<string, string> = {
		language: 'en',
		country: 'Samsung',
		url: '/terms',
	};
	return handleGetAdminTerms(queryParams);
};

export const getIsEU = (device: Device | null) => {
	if (device === null) {
		return false;
	}
	const countryName = getCountryNameFromDevice(device);
	const threeCharCountryCode = getThreeCharCountryCodeFromCountryName(countryName);
	const switzerlandAndUK = new Set(['CHE', 'GBR']);
	const isEU = eeaCountries.has(threeCharCountryCode) || switzerlandAndUK.has(threeCharCountryCode);
	return isEU;
};

export const getIsUSA = (device: Device | null) => {
	if (device === null) {
		return false;
	}
	const countryName = getCountryNameFromDevice(device);
	const threeCharCountryCode = getThreeCharCountryCodeFromCountryName(countryName);
	const isUSA = threeCharCountryCode === 'USA';
	return isUSA;
};
